import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../models/metadata/metadata.actions";
import { getMetadata } from "../models/metadata/metadata.selectors";
import { publicRoutes } from "./routersMap";
import { IncomingPage } from "../pages/incomingPage/IncomingPage";
import { Header } from "../components/header/Header";
import { Footer } from "../components/footer/Footer";
import { BottomButtonOrder } from "../components/UI/button/bottomButtonOrder/BottomButtonOrder";
import { ModalProvider } from "../models/modalContext/ModalContext";
import { Helmet } from "react-helmet";
import { fetchPages } from "../models/pages/pages.actions";
import { getPages } from "../models/pages/pages.selectors";

export const AppRouter = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const metadata = useSelector(getMetadata);
  const pages = useSelector(getPages);

  useEffect(() => {
    if (!metadata) {
      dispatch(fetchData());
      dispatch(fetchPages());
    }
  }, [dispatch, metadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const headerMainMenu = metadata?.menu?.header_main_menu || [];

  return (
    <ModalProvider>
      <div className="main_container">
        <Helmet>
          {" "}
          <title>«ВЕКТОР-М» – Разметка и строительство дорог</title>
          <meta
            name="description"
            content="«ВЕКТОР-М» – эффективная реализация проектов строительства, реконструкции и ремонта объектов транспортной инфраструктуры."
          />
        </Helmet>

        <Header />

        <Routes>
          {publicRoutes.map((route) => (
            <Route path={route.path} element={<route.element />} key={route.path} />
          ))}
          {metadata &&
            pages &&
            headerMainMenu.map((menuItem: any, i: number) => {
              const pageKey = menuItem.url.slice(1);
              const pageData = pages.find((page: any) => page.slug === pageKey);
              if (pageData) {
                return (
                  <Route
                    key={menuItem.url}
                    path={menuItem.url}
                    element={
                      <IncomingPage
                        htmlContent={pageData?.body}
                        title={pageData?.title}
                        description={pageData?.description}
                      />
                    }
                  />
                );
              } else {
                return null;
              }
            })}
        </Routes>

        <BottomButtonOrder />
        <Footer />
      </div>
    </ModalProvider>
  );
};
